/* eslint-disable max-lines */
/* eslint-disable indent */
/* eslint-disable max-len */
import { AppImages, variantProvider } from '@/app'
import { Icon, Image, Page, Text, Touchable, View } from '@/components'
import { useState } from 'react'
import ReactPlayer from 'react-player'

type BaseContent = {
	title: string
}

type VideoContent = BaseContent & {
	type: 'video'
	videoURL: string
}

type ArticleContent = BaseContent & {
	type: 'article'
	imageURL: string
	url: string
}

type ContentType = VideoContent | ArticleContent

type ContentCardProps = ContentType

const contents: ContentType[] = [
	{
		type: 'video',
		videoURL: 'https://x-cherry-web.s3.us-east-1.amazonaws.com/static/podcast_cherry_1.mp4',
		title: 'How it all began: Podcast - Part 1',
	},
	{
		type: 'video',
		videoURL: 'https://x-cherry-web.s3.us-east-1.amazonaws.com/static/podcast_cherry_2.mp4',
		title: 'How it all began: Podcast - Part 2',
	},
	{
		type: 'video',
		videoURL: 'https://x-cherry-web.s3.us-east-1.amazonaws.com/static/podcast_cherry_3.mp4',
		title: 'How it all began: Podcast - Part 3',
	},
	{
		type: 'article',
		imageURL:
			'https://x-cherry-web.s3.us-east-1.amazonaws.com/static/cherry_glamour_uk.webp',
		title: 'Glamour UK',
		url: 'https://www.glamourmagazine.co.uk/gallery/best-dating-sites#:~:text=Best%20new%20dating,pool%E2%80%A6%20for%20now',
	},
	{
		type: 'article',
		imageURL:
			'https://x-cherry-web.s3.us-east-1.amazonaws.com/static/cherry_sky_news.webp',
		title: 'Sky News',
		url: 'https://news.sky.com/story/phone-calls-anti-catfish-measures-and-vegan-apps-the-frustrated-entrepreneurs-taking-on-dating-13276624',
	},
]

const ContentCard = (props: ContentCardProps) => {
	const { type, title, videoURL, imageURL, url } = props
	const [isPlaying, setIsPlaying] = useState(false)
	const [isHovered, setIsHovered] = useState(false)

	const handlePlay = () => {
		setIsPlaying(true)
	}

	const handlePause = () => {
		setIsPlaying(false)
	}

	const handleOverlayClick = () => {
		setIsPlaying(true)
	}

	const handleMouseEnter = () => setIsHovered(true)
	const handleMouseLeave = () => setIsHovered(false)

	const handleArticleClick = () => {
		if (type === 'article' && url) {
			window.open(url, '_blank')
		}
	}

	return (
		<View css={styles.contentCardWrapper}>
			{type === 'video' ? (
				<View
					css={styles.mediaWrapper}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					<ReactPlayer
						url={videoURL}
						controls={isPlaying || isHovered}
						width={'100%'}
						height={'100%'}
						playing={isPlaying}
						onPlay={handlePlay}
						onPause={handlePause}
					/>
					{!isPlaying && !isHovered && (
						<Touchable css={styles.overlay} onPress={handleOverlayClick}>
							<Icon name={'play'} />
						</Touchable>
					)}
				</View>
			) : (
				<Touchable css={styles.mediaWrapper} onPress={handleArticleClick}>
					<Image
						source={imageURL}
						css={styles.contentImage}
						objectFit={'cover'}
					/>
				</Touchable>
			)}
			<View
				variants={['row', 'paddingTop:2']}
				responsiveVariants={{ small: ['column', 'gap:1', 'paddingTop:0'] }}
			>
				<Text
					text={title}
					variants={['h3', 'black700', 'bold']}
					responsiveVariants={{ small: ['h4', 'black700', 'bold'] }}
				/>
			</View>
		</View>
	)
}

const Media: React.FC = () => {
	return (
		<Page
			center={true}
			title='About'
			variants={['column', 'fullWidth', 'justifyCenter']}
			styles={{
				innerWrapper: styles.innerWrapper,
			}}
			headerProps={{ white: true }}
		>
			<Text variants={['h2', 'black700']} text={'About Us'} />
			<View
				variants={['marginTop:5', 'column']}
				responsiveVariants={{
					mid: ['marginTop:0', 'column'],
				}}
			>
				<View css={styles.wrapper}>
					<View variants={['column', 'fullWidth']} css={styles.fixedColumn}>
						<View css={styles.imageWrapper}>
							<Image
								objectFit={'cover'}
								css={styles.image}
								source={AppImages.AboutUs}
							/>
							<Text
								variants={['h3', 'bold', 'marginTop:2', 'black700']}
								text={'Founder & CEO Jo Mason'}
							/>
						</View>
					</View>
					<View variants={['column', 'fullWidth']}>
						<Text variants={['h3', 'black800']} text={'Press Release'} />
						<View variants={['column', 'gap:2']}>
							<Text
								variants={['h3', 'thin', 'marginTop:5', 'black700']}
								text={'New Cherry Dating App Set To Transform Market'}
							/>
							<Text
								style={styles.aboutText}
								text={
									'App designed to shake up industry, with full ID verification, innovative matching algorithmsand relationship coaching features'
								}
							/>
							<Text
								style={styles.aboutText}
								text={
									'New dating app, Cherry, launches this month, with a focus on shaking up what its founder calls a ‘stale market’. The brainchild of City financier and long-term dating app user, Jo Mason, Cherry has been designed for user safety and security, aiming to give users a place where they feel genuinely safe, and able to find anything from a casual date to a lifelong relationship.'
								}
							/>
							<Text
								style={styles.aboutText}
								text={
									'Cherry enters a crowded field – and one where users are often disenchanted. Talk to any dating app subscriber and you’ll hear tales of fake photos, fake ages, and completely fake people. This leads not only to disappointment and heartbreak, but also to serious criminal offences, from financial scams to stalking.'
								}
							/>
							<Text
								style={styles.aboutText}
								text={
									'“Being a dating app user today is incredibly frustrating,” says Jo Mason. “I kept coming across fake profiles, dodgy people and profiling questions that just weren’t relevant to the way we live today. I thought that it must be possible to do this better – and so I did!”.'
								}
							/>
							<Text
								style={styles.aboutText}
								text={
									'Jo wanted to fix these very real pain points, and so she put together a group of investors who backed her ideas for a dating app that’s fit for the users of today. “Most dating app users have been doing this for years,” she says. “And just like me, they know what they want. That’s why I’ve added full ID verification for additional security. And it’s why we let people decide what kind of level of companionship they’re looking for. This leads to tailored questions and a percentage compatibility match, so it’s easier to make decisions to take things further in a safe, secure environment.”'
								}
							/>
							<Text
								style={styles.aboutText}
								text={
									'This focus on allowing people to ‘cherry pick’ their matches means that users are far more likely to get the outcome they are looking for. Cherry wants to give quality over quantity to stop endless swiping. The app will also feature ‘dealbreaker’ questions, and plans to add professional life and relationship coaching. “Cherry is more than ‘just another dating app’ for me,” says Jo Mason. “It is a genuine, trustworthy and safe way to take the next step in your personal life.”'
								}
							/>
						</View>
					</View>
				</View>
				<View variants={['marginTop:10', 'column', 'gap:5']}>
					<Text text={'Check our content'} variants={['h3', 'black800']} />
					<View
						variants={['row', 'gap:5', 'wrap']}
						responsiveVariants={{ small: ['column', 'gap:2'] }}
					>
						{contents?.map((content, index) => {
							return (
								<ContentCard
									key={index}
									{...content}
								/>
							)
						})}
					</View>
				</View>
			</View>
		</Page>
	)
}

const styles = variantProvider.createComponentStyle(
	(theme) => ({
		wrapper: {
			maxWidth: 1280,
			display: 'grid',
			gridTemplateColumns: '1fr 2fr',
			height: '100%',
			// overflow: 'hidden',

			[theme.media.down('xlarge')]: {
				gridTemplateColumns: '1fr 1fr',
			},

			[theme.media.down('mid')]: {
				gridTemplateColumns: '1fr',
				...theme.spacing.marginTop(5),
			},
		},
		aboutText: {
			color: theme.colors.black700,
		},
		fixedColumn: {
			maxWidth: '100%',
			alignItems: 'center',
		},
		imageWrapper: {
			flexDirection: 'column',
			maxHeight: 600,
			...theme.spacing.marginRight(5),

			[theme.media.down('mid')]: {
				maxHeight: 400,
				...theme.spacing.marginBottom(5),
				...theme.spacing.marginRight(0),
			},
			[theme.media.down('small')]: {
				maxHeight: 515,
				...theme.spacing.marginBottom(8),
			},
		},
		image: {
			maxHeight: 600,
			borderRadius: theme.borderRadius.xSmall,
			...theme.spacing.marginRight(5),

			[theme.media.down('mid')]: {
				maxHeight: 400,
				...theme.spacing.marginRight(0),
			},
			[theme.media.down('small')]: {
				maxHeight: 515,
			},
		},
		innerWrapper: {
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
			paddingTop: theme.spacing.value(16),
		},
		ellipsisText: {
			// ellipsis if more than 2 lines
			display: '-webkit-box',
			WebkitLineClamp: 2,
			WebkitBoxOrient: 'vertical',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		contentCardWrapper: {
			maxWidth: 400,
			alignItems: 'alignStart',
			flexDirection: 'column',
			...theme.spacing.gap(0),

			[theme.media.down(1560)]: {
				maxWidth: 350,
			},

			[theme.media.down('xlarge')]: {
				maxWidth: 325,
			},

			[theme.media.down('mid')]: {
				maxWidth: '100%',
				width: '100%',
				flexDirection: 'row',
				...theme.spacing.gap(1),
			},

			[theme.media.down('small')]: {
				maxWidth: '100%',
				width: '100%',
				flexDirection: 'column',
				...theme.spacing.gap(1),
			},
		},
		mediaWrapper: {
			position: 'relative',
			width: '100%',
			minHeight: 225,
			aspectRatio: '16/9',
			cursor: 'pointer',
			borderRadius: theme.borderRadius.xSmall,
			overflow: 'hidden',

			[theme.media.down('mid')]: {
				maxWidth: 280,
				minWidth: 280,
			},

			[theme.media.down('small')]: {
				maxWidth: '100%',
				minHeight: 200,
			},
		},
		overlay: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			cursor: 'pointer',
		},
		contentImage: {
			width: '100%',
			height: '100%',
			transition: 'transform 0.3s ease',
			':hover': {
				transform: 'scale(1.05)',
			},
		},
	}),
	true
)

export default Media
