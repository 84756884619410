/* eslint-disable max-len */
/* eslint-disable max-lines */
import { React } from '@/app'
import { Page, View, Text, Link } from '@/components'
import { Compare } from '@/layout'
import { useApiURL } from '@/utils'

const privacyPolicyHtml = `
  <html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Arial Unicode MS";
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:"\@Arial Unicode MS";
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:"Noto Sans Symbols";}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
 p.email
  {display:none}
 @media(max-width:500px)
 {p.section
  {width:400px}
 p.section-list
  {width:320px}
 p.section-list-letter
  {width:280px}
 table.table
  {width:400px}
 p.email-table
  {display:none}
 p.email
  {display:block;
   margin-top:8px;
   text-align:center;
  }
 }
  @media(max-width:375px)
 {p.section
  {width:340px}
 p.section-list
  {width:260px}
 p.section-list-letter
  {width:200px}
 table.table
  {width:320px}
 }
h1
	{margin-top:12.0pt;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:0in;
	text-align:justify;
	line-height:115%;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.CoverPartyName, li.CoverPartyName, div.CoverPartyName
	{mso-style-name:"Cover Party Name";
	margin-top:12.0pt;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:155.95pt;
	text-indent:-28.35pt;
	line-height:115%;
	font-size:14.0pt;
	font-family:"Calibri",sans-serif;}
p.Parties1, li.Parties1, div.Parties1
	{mso-style-name:"Parties 1";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:39.3pt;
	text-align:justify;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Parties2, li.Parties2, div.Parties2
	{mso-style-name:"Parties 2";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:75.3pt;
	text-align:justify;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Background1, li.Background1, div.Background1
	{mso-style-name:"Background 1";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.5in;
	text-align:justify;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Background2, li.Background2, div.Background2
	{mso-style-name:"Background 2";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:1.0in;
	text-align:justify;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Background3, li.Background3, div.Background3
	{mso-style-name:"Background 3";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:1.5in;
	text-align:justify;
	text-indent:-9.0pt;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.DraftingNote, li.DraftingNote, div.DraftingNote
	{mso-style-name:"Drafting Note";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.5in;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	color:blue;}
p.DraftingNoteList1, li.DraftingNoteList1, div.DraftingNoteList1
	{mso-style-name:"Drafting Note List 1";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:1.0in;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	color:blue;}
p.DraftingNoteList2, li.DraftingNoteList2, div.DraftingNoteList2
	{mso-style-name:"Drafting Note List 2";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:1.5in;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	color:blue;}
p.DraftingNoteList3, li.DraftingNoteList3, div.DraftingNoteList3
	{mso-style-name:"Drafting Note List 3";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:2.0in;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	color:blue;}
p.DraftingNoteList4, li.DraftingNoteList4, div.DraftingNoteList4
	{mso-style-name:"Drafting Note List 4";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:2.5in;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	color:blue;}
p.DraftingNoteList5, li.DraftingNoteList5, div.DraftingNoteList5
	{mso-style-name:"Drafting Note List 5";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:3.0in;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	color:blue;}
p.Level1Bullet, li.Level1Bullet, div.Level1Bullet
	{mso-style-name:"Level 1 Bullet";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.5in;
	text-align:justify;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Level2Bullet, li.Level2Bullet, div.Level2Bullet
	{mso-style-name:"Level 2 Bullet";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:1.0in;
	text-align:justify;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Level6Number, li.Level6Number, div.Level6Number
	{mso-style-name:"Level 6 Number";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:3.0pt;
	margin-left:3.0in;
	text-align:justify;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Level7Number, li.Level7Number, div.Level7Number
	{mso-style-name:"Level 7 Number";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:3.0pt;
	margin-left:3.5in;
	text-align:justify;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Schedule, li.Schedule, div.Schedule
	{mso-style-name:Schedule;
	margin-top:12.0pt;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.75in;
	text-align:center;
	text-indent:-.5in;
	line-height:115%;
	page-break-before:always;
	border:none;
	padding:0in;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	text-transform:uppercase;
	font-weight:bold;}
p.ScheduleText, li.ScheduleText, div.ScheduleText
	{mso-style-name:"Schedule Text";
	margin-top:12.0pt;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.5in;
	text-align:center;
	text-indent:-.25in;
	line-height:115%;
	page-break-before:always;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	text-transform:uppercase;}
p.Part, li.Part, div.Part
	{mso-style-name:Part;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:1.0in;
	text-align:center;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	font-weight:bold;}
p.Appendix, li.Appendix, div.Appendix
	{mso-style-name:Appendix;
	margin-top:12.0pt;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.5in;
	text-align:center;
	text-indent:-.25in;
	line-height:115%;
	page-break-before:always;
	border:none;
	padding:0in;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	text-transform:uppercase;
	font-weight:bold;}
p.AppendixText, li.AppendixText, div.AppendixText
	{mso-style-name:"Appendix Text";
	margin-top:12.0pt;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.5in;
	text-align:center;
	text-indent:-.5in;
	line-height:115%;
	page-break-before:always;
	border:none;
	padding:0in;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	text-transform:uppercase;}
p.Sch6Number, li.Sch6Number, div.Sch6Number
	{mso-style-name:"Sch 6 Number";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:3.0pt;
	margin-left:4.0in;
	text-align:justify;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Sch7Number, li.Sch7Number, div.Sch7Number
	{mso-style-name:"Sch 7 Number";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:3.0pt;
	margin-left:4.5in;
	text-align:justify;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Definition, li.Definition, div.Definition
	{mso-style-name:Definition;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.5in;
	text-align:justify;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Definition1, li.Definition1, div.Definition1
	{mso-style-name:"Definition 1";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:1.0in;
	text-align:justify;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Definition2, li.Definition2, div.Definition2
	{mso-style-name:"Definition 2";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:1.5in;
	text-align:justify;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Definition3, li.Definition3, div.Definition3
	{mso-style-name:"Definition 3";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:2.0in;
	text-align:justify;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Prayer, li.Prayer, div.Prayer
	{mso-style-name:Prayer;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:.75in;
	text-align:justify;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.Prayer2, li.Prayer2, div.Prayer2
	{mso-style-name:Prayer2;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:12.0pt;
	margin-left:1.25in;
	text-align:justify;
	text-indent:-.25in;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.msoIns
	{mso-style-name:"";
	text-decoration:underline;
	color:teal;}
span.msoDel
	{mso-style-name:"";
	text-decoration:line-through;
	color:red;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:1.0in 1.0in 1.0in 1.0in;}
  @media(max-width:500px)
 {.WordSection1
  {width:400px}
  @media(max-width:375px)
 {.WordSection1
  {width:320px}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link=blue vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><b><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Privacy Policy for Cherry Dating </span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><b><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>This Privacy Policy</span></b><span
lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
color:black'> <b>is dated <span class=msoIns><ins
cite="mailto:Mills%20&amp;%20Reeve%20LLP" datetime="2023-12-06T08:10">December</ins></span><span
class=msoDel>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Cherry Dating for<i> </i>Apple iOS
and Android] (<b>the app</b>) is provided by Cherry Dating Ltd trading as
‘Cherry Dating’ (<b>‘we’, ‘our’ or ‘us’</b>). </span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>We take your privacy very
seriously. Please read this privacy policy carefully as it contains important
information on who we are and how and why we collect, store, use and share any information
relating to you (<b>your information</b>).</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>It also explains your rights in
relation to your information and how to contact us or the relevant regulator in
the event you have a complaint. Our collection, storage, use and sharing of
your information is regulated by law, including under the UK General Data
Protection Regulation (<b>UK GDPR</b>).</p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><b><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>If you are aged under 18 you must
not use the app as it is not designed for you.</span></b><span lang=EN-GB
style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
color:black'> We do not intend to collect the information of anyone under 18.
If you are aware that any information of anyone under 18 has been shared with
the app, please let us know so that we can delete that data. This version of
our privacy policy is written for adults.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>The app is distributed only on UK
app stores and is solely intended for use by people in the UK.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><b><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Agreement to installation of the
App and processing of data </span></b></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>By downloading the app, you agree
to our processing of your personal data (including your name, contact details
and device information (where applicable)) as described in the policy. Only
download the app if you have read the rules and agree to them.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><b><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>How you can withdraw consent</span></b></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>There are a number of different
lawful bases upon which we may process your personal data. The relevant lawful
bases we rely upon for our use of your personal data are explained below (see
“How and why we use your information”). Where we seek your consent to process
your personal data, you may change your mind and withdraw consent at any time
by either contacting us using the ‘How to Contact Us’ section below or by
deleting your account by accessing your account settings in the app, but that
will not affect the lawfulness of any processing carried out before you
withdraw your consent.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>This privacy policy is divided into
the following sections:</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;text-indent:-.5in;line-height:115%;
border:none'><span lang=EN-GB>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Important information about who we
are</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;text-indent:-.5in;line-height:115%;
border:none'><span lang=EN-GB>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>What this policy applies to</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;text-indent:-.5in;line-height:115%;
border:none'><span lang=EN-GB>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Information we collect about you</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;text-indent:-.5in;line-height:115%;
border:none'><span lang=EN-GB>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Location services/data</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;text-indent:-.5in;line-height:115%;
border:none'><span lang=EN-GB>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Use of the camera on your device </span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;text-indent:-.5in;line-height:115%;
border:none'><span lang=EN-GB>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Use of the microphone on your
device </span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;text-indent:-.5in;line-height:115%;
border:none'><span lang=EN-GB>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>How your information is collected</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;text-indent:-.5in;line-height:115%;
border:none'><span lang=EN-GB>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>How and why we use your information</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;text-indent:-.5in;line-height:115%;
border:none'><span lang=EN-GB>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Marketing</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;text-indent:-.5in;line-height:115%;
border:none'><span lang=EN-GB>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Who we share your information with</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;text-indent:-.5in;line-height:115%;
border:none'><span lang=EN-GB>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>How long your information will be
kept</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;text-indent:-.5in;line-height:115%;
border:none'><span lang=EN-GB>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Transferring your information out
of the UK </span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;text-indent:-.5in;line-height:115%;
border:none'><span lang=EN-GB>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Your rights</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;text-indent:-.5in;line-height:115%;
border:none'><span lang=EN-GB>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Keeping your information secure</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;text-indent:-.5in;line-height:115%;
border:none'><span lang=EN-GB>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>How to complain</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;text-indent:-.5in;line-height:115%;
border:none'><span lang=EN-GB>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Changes to this privacy policy</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;text-indent:-.5in;line-height:115%;
border:none'><span lang=EN-GB>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>How to contact us</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;text-indent:-.5in;line-height:115%;
border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.5in;line-height:115%;
border:none'><b><u><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Important information and who we
are</span></u></b></p>

<p class="MsoNormal section" style='text-align:justify'><span lang=EN-GB
style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>We are
the controller of your information obtained via the app, meaning we are the
organisation legally responsible for deciding how and for what purposes it is
used.</span></p>

<p class="MsoNormal section" style='text-align:justify'><span lang=EN-GB
style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>We
have appointed a data protection officer (DPO). If you have any questions about
this privacy policy, please contact them using the ‘How to contact us’ section
below. </span></p>

<h1><u><span lang=EN-GB>What this policy applies to</span></u></h1>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>This privacy policy relates to your
use of the app only.</span></p>

<h1><a name="_heading=h.gjdgxs"></a><u><span lang=EN-GB>Information we collect
about you</span></u></h1>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>The information we collect about
you depends on the particular activities carried out through the app. Most of
the information we collect about you is provided to us directly by you (for
further information on how we collect data please see the section ‘How your
information is collected’ below.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Sometimes you can choose if you
want to give us your information and let us use it. Where that is the case we
will tell you and give you the choice before you give the information to us. We
will also tell you whether declining to share the information will have any
effect on <i>your use of the app or our services</i>.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>We will collect and use the
following information about you:</span></p>

<table class=table border=1 cellspacing=0 cellpadding=0 style='margin-left:
 5.9pt;border-collapse:collapse;border:none'>
 <thead>
  <tr style='height:22.5pt'>
   <td width=176 valign=top style='width:132.15pt;border:solid black 1.0pt;
   padding:0in 5.75pt 0in 5.75pt;height:22.5pt'>
   <p class=MsoNormal style='text-align:justify'><span lang=EN-GB
   style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>Category
   of data</span></p>
   </td>
   <td width=417 valign=top style='width:312.75pt;border:solid black 1.0pt;
   border-left:none;padding:0in 5.75pt 0in 5.75pt;height:22.5pt'>
   <p class=MsoNormal style='text-align:justify'><span lang=EN-GB
   style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>In
   more detail</span></p>
   </td>
  </tr>
 </thead>
 <tr style='height:.35in'>
  <td width=176 valign=top style='width:132.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Identity and Account Data you
  give us when you register </span></p>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Registration is mandatory in
  order to use the app</span></p>
  </td>
  <td width=417 valign=top style='width:312.75pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>your name, address and contact information, including email
  address and mobile number </span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>information to check and verify your identity, eg date of birth</span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>your gender</span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>your account details, such as username and password</span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>your replies to security questions if applicable </span></p>
  </td>
 </tr>
 <tr style='height:.35in'>
  <td width=176 valign=top style='width:132.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Other Identity and Account Data
  you may give us </span></p>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Please see the ‘Special
  Categories of Data’ section below.</span></p>
  </td>
  <td width=417 valign=top style='width:312.75pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>sexual orientation</span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>ethnicity</span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>religious beliefs</span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>political opinions </span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>your personal or professional interests</span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>details of your job title and salary </span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>biometric data </span></p>
  </td>
 </tr>
 <tr style='height:.35in'>
  <td width=176 valign=top style='width:132.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Location Data</span></p>
  </td>
  <td width=417 valign=top style='width:312.75pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>details of your location with a high degree of precision, see
  the section ‘<b>Location services/data</b>’ below</span></p>
  </td>
 </tr>
 <tr>
  <td width=176 valign=top style='width:132.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Device Data</span></p>
  </td>
  <td width=417 valign=top style='width:312.75pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>includes the type of mobile device you use, a unique device
  identifier (for example, your Device's IMEI number, the MAC address of the
  Device's wireless network interface, or the mobile phone number used by the
  Device), mobile network information, your mobile operating system, the type
  of mobile browser you use and time zone setting</span></p>
  </td>
 </tr>
 <tr>
  <td width=176 valign=top style='width:132.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Content Data</span></p>
  </td>
  <td width=417 valign=top style='width:312.75pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>includes information stored on your device, including login
  information, photos, videos or other digital content shared with your consent</span></p>
  </td>
 </tr>
 <tr>
  <td width=176 valign=top style='width:132.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Profile Data </span></p>
  </td>
  <td width=417 valign=top style='width:312.75pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>username </span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>preferences</span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>information on users you follow or have communicated with</span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>your direct messages</span></p>
  </td>
 </tr>
 <tr>
  <td width=176 valign=top style='width:132.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Usage Data</span></p>
  </td>
  <td width=417 valign=top style='width:312.75pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>includes details of your use of any of our app including, but
  not limited to, traffic data and other communication data, whether this is
  required for our own billing purposes or otherwise and the resources that you
  access</span></p>
  </td>
 </tr>
 <tr>
  <td width=176 valign=top style='width:132.15pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Marketing and Communications Data
  </span></p>
  </td>
  <td width=417 valign=top style='width:312.75pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>includes your preferences in receiving marketing from us and our
  third parties and your communication preferences</span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>your contact history (contact between you and us, and between
  you and other users) </span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
  margin-left:24.0pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>details of any information, feedback or other matters you give
  to us by phone, email, post or via social media</span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><b><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>&nbsp;</span></b></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><b><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Special Categories of data</span></b></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Certain personal data we collect is
treated as a special category to which additional protections apply under data
protection law:</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>data revealing racial or ethnic
origin, political opinions, religious beliefs, philosophical beliefs </span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>biometric data (when used to
uniquely identify an individual)</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>data concerning health, sex life or
sexual orientation</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>data concerning your occupation </span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Where we process such special
category personal data, we will also ensure we are permitted to do so under
data protection laws.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>We collect and use your information
for the purposes described in the section ‘<b>How and why we use your
information</b>’ below. </span></p>

<h1><a name="_heading=h.1fob9te"></a><u><span lang=EN-GB>Location services/data</span></u></h1>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>The app will request your consent
to use location services to precisely identify your location each session. We
require access to that data in order to identify real world locations near you
to allow us to find other users in your geographical area.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>If you do not provide your agreement,
you may use the app but that will mean that we cannot find other users in your
geographical area. To withdraw your consent at any time please contact us using
the ‘How to contact us’ section below (that will not affect the lawfulness of
our use of that data in reliance on the consent before it was withdrawn).</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>We will not process your location
data other than as strictly required to allow us to find other users in your
geographical area.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>The location services in the app
will not operate unless location services/data are generally enabled on your
device. You may disable such functionality at any time by accessing the
location data settings on your device and disabling them. When you allow your
device to use location services/data, data will also be collected by the
organisations providing the device operating system<i> in accordance with </i>thei<i>r
</i>third party privacy policies<i>.</i></span></p>

<h1><a name="_heading=h.3znysh7"></a><u><span lang=EN-GB>Use of the camera and
photo gallery on your device </span></u></h1>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Permission will be requested to use
the camera on your device in order to take and upload pictures to your profile.
</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>You may disable the app’s access to
the camera on your device at any time by accessing the camera settings on your
device and disabling them, although that will mean that the photo upload
feature on the app is no longer available.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Data gathered by the camera for
such purposes remains under your control on the device and we do not have
access to it.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><b><u><span lang=EN-GB style='font-size:10.0pt;line-height:
115%;font-family:"Arial",sans-serif;color:black'>Use of the device microphone </span></u></b></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Permission will be requested to use
the microphone on your device in order to engage in live audio and video calls
over the app. </span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>You may disable the app’s access to
the microphone on your device at any time by accessing the microphone settings
on your device and disabling them, although that will mean that the live audio
and video call feature on the app is no longer available.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Data gathered by the microphone for
such purposes remains under your control on the device and we do not have
access to it.</span></p>

<h1><a name="_heading=h.2et92p0"></a><u><span lang=EN-GB>How your information
is collected</span></u></h1>

<p class="MsoNormal section" style='text-align:justify'><b><span lang=EN-GB
style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>Information
you give us</span></b></p>

<p class="MsoNormal section" style='text-align:justify'><span lang=EN-GB
style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>This
is information you give us about you by filling in forms on the app or by
corresponding with us (for example, by email or chat). It includes:</span></p>

<p class="MsoNormal section-list" style='margin-left:.5in;text-align:justify;text-indent:-.25in'><span
lang=EN-GB style='font-size:10.0pt;line-height:107%;font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:107%;
font-family:"Arial",sans-serif'>Information you provide when you register to
use the app. In order to register to use the app you will need to provide us with
your, name, phone number, email address, date of birth and gender. </span></p>

<p class="MsoNormal section-list" style='margin-left:.5in;text-align:justify;text-indent:-.25in'><span
lang=EN-GB style='font-size:10.0pt;line-height:107%;font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:107%;
font-family:"Arial",sans-serif'>Information you chose to provide when you
create a profile on the app, such as sexual orientation, ethnicity, religious
beliefs, political beliefs, personal and professional interests. You may also choose
to share additional information such as photos, voice notes and videos (in
order to upload this additional information you may allow us access to your
camera and microphone as detailed in the section above). </span></p>

<p class="MsoNormal section-list" style='margin-left:.5in;text-align:justify;text-indent:-.25in'><span
lang=EN-GB style='font-size:10.0pt;line-height:107%;font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:107%;
font-family:"Arial",sans-serif'>If you contact us via email or social media we
will collect certain information from you, such as Communications, Content,
Profile and Contact Data. </span></p>

<p class="MsoNormal section-list" style='margin-left:.5in;text-align:justify;text-indent:-.25in'><span
lang=EN-GB style='font-size:10.0pt;line-height:107%;font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:107%;
font-family:"Arial",sans-serif'>Information you share with us about other users
and the direct messages you share.</span></p>

<p class="MsoNormal section-list" style='margin-left:.5in;text-align:justify;text-indent:-.25in'><span
lang=EN-GB style='font-size:10.0pt;line-height:107%;font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:107%;
font-family:"Arial",sans-serif'>Information you share with us about other users
in relation to dating preferences.</span></p>

<p class="MsoNormal section" style='text-align:justify'><b><span lang=EN-GB
style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>Information
we collect about you and your device</span></b></p>

<p class="MsoNormal section" style='text-align:justify'><span lang=EN-GB
style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>Each
time you visit our app we will automatically collect personal data including
Device Data and Usage Data. We collect this data using cookies and other
similar technologies. Please see our Cookie Policy for further details.</span></p>

<p class="MsoNormal section" style='text-align:justify'><b><span lang=EN-GB
style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>Location
Data</span></b></p>

<p class="MsoNormal section" style='text-align:justify'><span lang=EN-GB
style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>We
also use GPS technology to determine your current location. Some of our
location-enabled services require your personal data for the feature to work.
If you wish to use the particular feature, you will be asked to consent to your
data being used for this purpose. You can withdraw your consent at any time by
disabling Location Data in your settings,</span></p>

<p class="MsoNormal section" style='text-align:justify'><b><span lang=EN-GB
style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>Information
we receive from other sources including third parties</span></b></p>

<p class="MsoNormal section" style='text-align:justify'><span lang=EN-GB
style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>We
will receive personal data about you from various third parties as set out
below:</span></p>

<p class="MsoNormal section-list" style='margin-left:38.25pt;text-align:justify;text-indent:
-.25in'><span lang=EN-GB style='font-size:10.0pt;line-height:107%;font-family:
"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:107%;
font-family:"Arial",sans-serif'>Device Data from the following parties:
analytics providers such as Google based outside the UK and Firebase based
outside the UK</span></p>

<p class="MsoNormal section-list" style='margin-left:38.25pt;text-align:justify;text-indent:
-.25in'><span lang=EN-GB style='font-size:10.0pt;line-height:107%;font-family:
"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:107%;
font-family:"Arial",sans-serif'>Contact, Financial and Transaction Data from
providers of technical, payment and delivery services.</span></p>

<p class="MsoNormal section-list" style='margin-left:38.25pt;text-align:justify;text-indent:
-.25in'><span lang=EN-GB style='font-size:10.0pt;line-height:107%;font-family:
"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:107%;
font-family:"Arial",sans-serif'>Identity, Contact and Communications Data from
other users when they interact with you or if they report you to us. </span></p>

<p class="MsoNormal section-list" style='margin-left:38.25pt;text-align:justify;text-indent:
-.25in'><span lang=EN-GB style='font-size:10.0pt;line-height:107%;font-family:
"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:107%;
font-family:"Arial",sans-serif'>Identity, Contact and Profile Data from your
other Social Media accounts should you choose to integrate your Instagram
account with our app. </span></p>

<h1><a name="_heading=h.tyjcwt"></a><u><span lang=EN-GB>How and why we use your
information</span></u></h1>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Under data protection law, we can
only use your information if we have a proper reason, eg:</span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>where you have given consent</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>to comply with our legal and
regulatory obligations</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>for the performance of a contract
with you or to take steps at your request before entering into a contract, or</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>for our legitimate interests or
those of a third party</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>A legitimate interest is when we
have a business or commercial reason to use your information, so long as this
is not overridden by your own rights and interests. We will carry out an
assessment when relying on legitimate interests, to balance our interests
against your own. You can obtain details of this assessment by contacting us
(see ‘<b>How to contact us</b>’ below).</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>The table below explains what we
use your information for and why.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Note that the particular lawful
basis on which we process your personal data will depend on the specific
purpose for which we are using your data. Please contact us if you need more
details about the particular legal basis we are relying on where more than one
ground has been set out in the table below. </span></p>

<table class=table border=1 cellspacing=0 cellpadding=0 style='margin-left:
 5.9pt;border-collapse:collapse;border:none'>
 <thead>
  <tr style='height:22.5pt'>
   <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
   padding:0in 5.75pt 0in 5.75pt;height:22.5pt'>
   <p class=MsoNormal style='text-align:justify'><span lang=EN-GB
   style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>What
   we use your information for</span></p>
   </td>
   <td width=296 valign=top style='width:222.35pt;border:solid black 1.0pt;
   border-left:none;padding:0in 5.75pt 0in 5.75pt;height:22.5pt'>
   <p class=MsoNormal style='text-align:justify'><span lang=EN-GB
   style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>Our
   reasons</span></p>
   </td>
  </tr>
 </thead>
 <tr style='height:.35in'>
  <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Create and manage your account
  with us</span></p>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>
  </td>
  <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Necessary to perform our contract
  with you/provide our service to you. We use your information to maintain your
  account and your profile.</span></p>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>To the extent we need to process
  special categories of personal data to create and manage you account with us,
  we do so only where you have given explicit consent. </span></p>
  </td>
 </tr>
 <tr style='height:.35in'>
  <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Providing our dating services
  and/or the functionalities of the app to you</span></p>
  </td>
  <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Depending on the circumstances to
  perform our contract with you or to take steps at your request before
  entering into a contract or for our legitimate interests, i.e. to be as
  efficient as we can so we can deliver the best service to you. By way of
  example, the best service may be by using your information to suggest the
  best data driven romantic match for you. </span></p>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>In relation to the geolocation <i>function
  on the app</i> we will use data relating to your location only based on your
  consent as described in ‘<b>Location services/data</b>’ (above)</span></p>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>To the extent we need to process
  special categories of personal data to provide our dating services and/or the
  functionalities of the app to you, we do so only where you have given
  explicit consent. </span></p>
  </td>
 </tr>
 <tr style='height:.35in'>
  <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Conducting checks to identify you
  and verify your identity or otherwise to help prevent and detect fraud
  against you or us</span></p>
  </td>
  <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Depending on the circumstances to
  comply with our legal and regulatory obligations or for our legitimate
  interests in order<i> </i>to minimise the risk of account or identity theft
  or fraud that could be damaging for you, a third party or us and to help keep
  our users safe.</span></p>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>To the extent we need to process
  special categories of personal data to conduct checks to identify you and
  verify your identity or otherwise to help prevent and detect fraud against
  you or us, we do so only where you have explicit consent. </span></p>
  </td>
 </tr>
 <tr style='height:.35in'>
  <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>To enforce legal rights or defend
  or undertake legal proceedings</span></p>
  </td>
  <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Depending on the circumstances,
  to comply with our legal and regulatory obligations or in other cases, for
  our legitimate interests, i.e. to protect our business, interests and rights
  in the event we need to enforce legal rights or defend or undertake legal
  proceedings.</span></p>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>To the extent we need to process
  special categories of personal data to enforce legal rights or defend or
  undertake legal proceedings, we do so on the condition it is necessary for
  the establishment, exercise or defence of legal claims. </span></p>
  </td>
 </tr>
 <tr style='height:.35in'>
  <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Communications with you not
  related to marketing, including about changes to our terms or policies or
  changes to the app or service or other important notices or information </span></p>
  </td>
  <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Depending on the circumstances,
  where necessary to perform our contract with you or for our legitimate
  interests, i.e. to provide the best service to you and to ensure you are
  aware of changes that affect you when using our app or service. </span></p>
  </td>
 </tr>
 <tr style='height:.35in'>
  <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>To help you connect with other
  users by recommending you to other users and vice versa, showing user
  profiles to one another and enabling users to search and connect with one
  another </span></p>
  </td>
  <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>To perform our contract with
  you/provide our service to you. We use your information to make your profile
  viewable to other users and recommend other users to you and vice versa.</span></p>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>To the extent we need to process
  special categories of personal data to help you connect with other users by
  recommending you to other users and vice versa, showing user profiles to one
  another and enabling users to search and connect with one another, we do so
  only where you have given your explicit consent. </span></p>
  </td>
 </tr>
 <tr style='height:.35in'>
  <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Protect the security of systems
  and data used to provide the app and its services</span></p>
  </td>
  <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>To comply with our legal and
  regulatory obligations. </span></p>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>We may also use your information
  to ensure the security of systems and data to a standard that goes beyond our
  legal obligations, and in those cases our reasons are for our legitimate
  interests, i.e. to protect systems and data and to prevent and detect
  criminal activity that could be damaging for you and/or us.</span></p>
  </td>
 </tr>
 <tr style='height:.35in'>
  <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Operational reasons, such as
  improving our services on the app and providing new features and services to
  you </span></p>
  </td>
  <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>For our legitimate interests, i.e.
  to be as efficient as we can so we can deliver the best service to you<b>.</b></span></p>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>To the extent we need to process
  special categories of personal data for operational reasons such as improving
  our services on the app and providing new features and services to you, we do
  so only where you have given your explicit consent. </span></p>
  </td>
 </tr>
 <tr style='height:.35in'>
  <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Statistical analysis to help us
  understand our users </span></p>
  </td>
  <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>For our legitimate interests, i.e.
  to be as efficient as we can so we can deliver the best service to you.</span></p>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>To the extent we need to process
  special categories of personal data for statistical analysis to help us
  understand our users, we do so only where you have given your explicit
  consent. </span></p>
  </td>
 </tr>
 <tr style='height:.35in'>
  <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Updating and enhancing customer
  records</span></p>
  </td>
  <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Depending on the circumstances,
  to perform our contract with you or to comply with our legal and regulatory
  obligations or where neither of the above apply, for our legitimate
  interests, i.e., making sure we can keep in touch with our users.  </span></p>
  </td>
 </tr>
 <tr style='height:.35in'>
  <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Disclosures and other activities
  necessary to comply with legal and regulatory obligations, eg to record and
  demonstrate evidence of your consent to our use of your information where
  relevant</span></p>
  </td>
  <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>To comply with our legal and
  regulatory obligations. </span></p>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>To the extent we need to process
  special categories of personal data for disclosures and other activities
  necessary to comply with legal and regulatory obligations, we do so either on
  the condition it is necessary for the establishment, exercise or defence of
  legal claims or where you have given your explicit consent. </span></p>
  </td>
 </tr>
 <tr style='height:.35in'>
  <td width=297 valign=top style='width:222.55pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>To market our services to you and
  measure the effectiveness of advertising campaigns on our app </span></p>
  </td>
  <td width=296 valign=top style='width:222.35pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt;height:.35in'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>For our legitimate interests, i.e.
  to promote our app to existing and former users.</span></p>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>See ‘<b>Marketing</b>’ below for
  further information.</span></p>
  </td>
 </tr>
</table>

<h1><a name="_heading=h.3dy6vkm"></a><u><span lang=EN-GB>Marketing</span></u></h1>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>We will use your information to
send you updates (by email or text message) about our app, including exclusive
offers, updates or new services.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>We have a legitimate interest in
using your information for marketing purposes (see above ‘<b>How and why we use
your information</b>’). This means we do not need your consent to send you
marketing information. If we change our marketing approach in the future so
that consent is needed, we will ask for this separately and clearly.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>You have the right to opt out of
receiving marketing communications at any time by:</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>contacting us at using the details
at the ‘How to contact us’ section </span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>using the ‘unsubscribe’ link in
emails </span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>We will always treat your
information with the utmost respect and never sell or share it with other
organisations for marketing purposes.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>For more information on your right
to object at any time to your information being used for marketing purposes,
see ‘<b>Your rights</b>’ below.</span></p>

<h1><a name="_heading=h.1t3h5sf"></a><u><span lang=EN-GB>Who we share your
information with</span></u></h1>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>We routinely share your information
with:</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>other users when you disclose
information on the service (including your public profile).</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>service providers we use to help us
run our business or provide the services or functionalities in the app, such as
data hosting and maintenance, analytics, payment processing, ID verification,
marketing and advertising. </span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Noto Sans Symbols";color:black'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Apple or Google<i> </i>Stores </span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>We only allow the companies
referred to above to handle your information if we are satisfied they take
appropriate measures to protect your information. We also impose contractual
obligations on service providers to ensure they can only use your information
to provide services to us and to you. </span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>We or the third parties mentioned
above occasionally also need to share your information with:</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>our or their<i> </i>external
auditors—the recipient of the information will be bound by confidentiality
obligations</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>our or their professional advisors
(such as lawyers and other advisors)—the recipient of the information will be
bound by confidentiality obligations</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>law enforcement agencies, courts or
tribunals and regulatory bodies to comply with legal and regulatory obligations</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>other parties that have or may
acquire control or ownership of our business (and our or their professional
advisers) in connection with a significant corporate transaction or
restructuring, including a merger, acquisition, asset sale, initial public
offering or in the event of our insolvency—usually, information will be
anonymised but this may not always be possible. The recipient of any of your
information will be bound by confidentiality obligations.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>More details about who we share
your information with and why are set out below: </span></p>

<table class=table border=1 cellspacing=0 cellpadding=0 style='border-collapse:
 collapse;border:none'>
 <tr>
  <td width=199 valign=top style='width:149.45pt;border:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Recipient</span></p>
  </td>
  <td width=201 valign=top style='width:150.85pt;border:solid black 1.0pt;
  border-left:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Processing operation (use) by
  recipient</span></p>
  </td>
  <td width=201 valign=top style='width:150.5pt;border:solid black 1.0pt;
  border-left:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Relevant categories of your
  information transferred to recipient</span></p>
  </td>
 </tr>
 <tr>
  <td width=199 valign=top style='width:149.45pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Onfido Limited, a company
  incorporated and registered in England and Wales with company number 07479524
  whose registered office is at 14-18 Finsbury Square, 3<sup>rd</sup> Floor,
  London, England EC2A 1AH (‘Onfido’).</span></p>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Onfido is a third-party ID
  verification platform. </span></p>
  </td>
  <td width=201 valign=top style='width:150.85pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Captures identity documents and
  facial photographs/video of you for the purpose verifying your identity for
  us. Onfido do this by conducting a:</span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
  margin-left:16.4pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>Document Check to assess the likelihood documents provided are
  genuine; and </span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
  margin-left:16.4pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>Facial Similarity Check (Selfie) which compares the face
  displayed on a Document with facial image captured of you to verify they are
  the same.</span></p>
  </td>
  <td width=201 valign=top style='width:150.5pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
  margin-left:17.85pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>Image(s) of the identity document and information describing the
  identity document</span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
  margin-left:17.85pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>Information extracted from the document electronically where
  applicable</span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
  margin-left:17.85pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>Image of your face</span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
  margin-left:17.85pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>Image of the face in the identity document</span></p>
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
  margin-left:17.85pt;text-align:justify;text-indent:-.25in;line-height:115%;
  border:none'><span lang=EN-GB style='font-family:"Arial",sans-serif'>-<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'>Numerical biometric data (special category of data)</span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black;background:yellow'>&nbsp;</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>We will not share your information
with any other third party.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>If you would like more information
about who we share your information with and why, please contact us (see ‘<b>How
to contact us</b>’ below).</span></p>

<h1><a name="_heading=h.4d34og8"></a><u><span lang=EN-GB>How long your
information will be kept</span></u></h1>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Different retention periods apply
for different types of your information. We only keep your personal information
for as long as we need it for either a legitimate business purpose or as
permitted by applicable law.</span><span lang=EN-GB> </span><span lang=EN-GB
style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
color:black'>In some circumstances you can ask us to delete your data: see ‘Your
rights’ below for further information.</span></p>

<h1><a name="_heading=h.4d34og8"></a><u><span lang=EN-GB>Account Deletion</span></u></h1>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>If you decide to stop using our App, you can close your account and your profile will stop being visible to other users.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>In order to delete your account, please follow these steps:</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Under your profile setting – select “Account Settings” – you can choose from 2 options - delete or de-activate your account.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>If you choose to delete your account, you may do so at any time through the account settings within the app. Upon initiating account deletion, the following terms apply:</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Data Retention During Deletion Process</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Once a deletion request is submitted, your account will be scheduled for deletion and permanently removed from our systems within 30 days, unless otherwise required by law.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>During this period, your data will no longer be accessible or used for any purpose.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Immediate Effects of Deletion</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Upon deletion, your profile, settings, and associated data, including any content shared within the app, will be permanently erased.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Any connections, interactions, or messages exchanged with other users may remain visible to them but will be anonymized, as applicable.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Backup and Legal Compliance</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Some data may be retained in backups or logs for a limited period (up to 90 days) to comply with legal requirements, prevent fraud, or resolve disputes.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Any retained data will be securely stored and deleted once no longer required.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Third-Party Services</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>If you used third-party logins (e.g., Meta, Google) or connected accounts, we recommend reviewing and updating permissions within those platforms as account deletion on our app does not automatically revoke such permissions.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Reactivation Policy</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Once the deletion process is completed, your account and data cannot be recovered. You may create a new account but will not have access to any previous data or settings.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>For assistance with account deletion or if you encounter any issues during the process, please contact our support team at customerservices@cherrydating.co.uk</span></p>

<!-- New Sections Added Below -->

<h1><a name="_heading=h.4d34og9"></a><u><span lang=EN-GB>Data Processing Information</span></u></h1>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>To ensure transparency and compliance with applicable laws and regulations, including Meta's requirements, we provide the following details about the data we process:</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Types of Data We Process</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Personal Information: Name, email address, phone number, date of birth, gender, and profile pictures.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Usage Data: IP address, device information (e.g., device type, operating system), and app usage patterns (e.g., pages visited, features used).</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Content Data: Messages, photos, videos, and other content shared within the app.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Location Data: Approximate or precise location data, if you enable location services.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Third-Party Data: Information from third-party platforms (e.g., Meta, Google) if you use these services to log in or connect your account.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>How We Process Your Data</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Account Management: To create and manage your account, verify your identity, and provide customer support.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>App Functionality: To enable features such as matching, messaging, and profile customization.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Improvement and Analytics: To analyze app usage, improve our services, and develop new features.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Marketing and Communication: To send you promotional offers, updates, and notifications (you can opt out at any time).</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Legal and Security Purposes: To comply with legal obligations, prevent fraud, and ensure the security of our app and users.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>How Users Can Request Deletion of Their Data</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>You can request the deletion of your data by following these steps:</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Go to your <strong>Profile Settings</strong>.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Select <strong>Account Settings</strong>.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Choose <strong>Delete Account</strong>.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Alternatively, you can contact our support team at <strong>customerservices@cherrydating.co.uk</strong> to request deletion. We will process your request within <strong>30 days</strong>, subject to legal or operational requirements.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Data Sharing and Third-Party Access</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Service Providers: Third-party vendors who assist us in operating the app (e.g., hosting, analytics, payment processing).</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Legal Authorities: If required by law or to protect our rights and the safety of our users.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Third-Party Platforms: If you use third-party logins (e.g., Meta, Google), certain data may be shared with these platforms as per their privacy policies.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>User Rights</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Access: You can request a copy of the data we hold about you.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Correction: You can update or correct inaccurate data.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Restriction: You can request that we restrict the processing of your data in certain circumstances.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Portability: You can request a transfer of your data to another service provider.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>Objection: You can object to the processing of your data for specific purposes, such as marketing.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black'>To exercise these rights, please contact us at <strong>customerservices@cherrydating.co.uk</strong>.</span></p>

<h1><a name="_heading=h.2s8eyo1"></a><u><span lang=EN-GB>Transferring your
information out of the UK</span></u></h1>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>The UK
and other countries outside <span class=msoDel></span>the UK have differing data protection laws, some of which may
provide lower levels of protection of privacy.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>It is sometimes necessary for us to
transfer your personal data to countries outside the UK<span class=msoDel></span>.
In those cases, we will only do this where we have appropriate measures in
place to protect your personal data. </span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Your data may be processed by other
users of the app that are outside of the UK<span class=msoDel></span>.
This may happen to allow users to contact each other via direct messaging,
fulfil a swap, deliver an item, process payment details or provide support
services and enable us to run the app.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Under data protection laws, we can
only transfer your personal data to a country outside the UK<span class=msoDel></span>
where:</span></p>

<p class="MsoListParagraphCxSpFirst section-list" style='margin-bottom:12.0pt;text-align:justify;
text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB
style='font-size:10.0pt;line-height:115%;font-family:Symbol;color:black'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>in the case of transfers subject to
UK data protection law, the UK government has decided the particular country
ensures an adequate level of protection of personal data (known as an ‘adequacy
regulation’) further to Article 45 of the UK GDPR. A list of countries the UK
currently has adequacy regulations in relation to is available at
https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/international-transfers/international-transfers-a-guide/#adequacy.</span></p>

<p class="MsoListParagraphCxSpMiddle section-list" style='margin-bottom:12.0pt;text-align:
justify;text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB
style='font-size:10.0pt;line-height:115%;font-family:Symbol;color:black'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>there are appropriate safeguards in
place, together with enforceable rights and effective legal remedies for you,
or</span></p>

<p class="MsoListParagraphCxSpLast section-list" style='margin-bottom:12.0pt;text-align:justify;
text-indent:-.25in;line-height:115%;border:none'><span lang=EN-GB
style='font-size:10.0pt;line-height:115%;font-family:Symbol;color:black'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>a specific exception applies under
relevant data protection law</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Where we transfer your personal
data outside the UK we do so on the basis of an adequacy regulation or (where
this is not available) legally-approved standard data protection clauses
recognised or issued further to Article 46(2) of the UK GDPR. In the event we
cannot or choose not to continue to rely on either of those mechanisms at any
time we will not transfer your personal data outside the UK unless we can do so
on the basis of an alternative mechanism or exception provided by UK data
protection law and reflected in an update to this policy.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Any changes to the destinations to
which we send your information or in the transfer mechanisms we use to transfer
your information internationally will be notified to you in accordance with the
section on ‘<b>Change to this privacy policy</b>’ below.</span></p>

<h1><a name="_heading=h.17dp8vu"></a><u><span lang=EN-GB>Your rights</span></u></h1>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>You generally have the following
rights, which you can usually exercise free of charge:</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Request access to your personal
data (commonly known as a &quot;data subject access request&quot;). This
enables you to receive a copy of the personal data we hold about you and to
check that we are lawfully processing it.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Request correction of the personal
data that we hold about you. This enables you to have any incomplete or
inaccurate data we hold about you corrected, though we may need to verify the
accuracy of the new data you provide to us.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Request erasure of your personal
data. This enables you to ask us to delete or remove personal data where there
is no good reason for us continuing to process it. You also have the right to
ask us to delete or remove your personal data where you have successfully
exercised your right to object to processing (see below), where we may have
processed your information unlawfully or where we are required to erase your
personal data to comply with local law. Note, however, that we may not always
be able to comply with your request of erasure for specific legal reasons which
will be notified to you, if applicable, at the time of your request.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Object to processing of your
personal data where we are relying on a legitimate interest (or those of a
third party) and there is something about your particular situation which makes
you want to object to processing on this ground as you feel it impacts on your
fundamental rights and freedoms. You also have the right to object where we are
processing your personal data for direct marketing purposes. In some cases, we
may demonstrate that we have compelling legitimate grounds to process your
information which override your rights and freedoms.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Request restriction of processing
of your personal data. This enables you to ask us to suspend the processing of
your personal data in the following scenarios:</span></p>

<p class="MsoNormal section-list-letter" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:56.7pt;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB>a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>if you want us to establish the
data's accuracy;</span></p>

<p class="MsoNormal section-list-letter" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:56.7pt;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB>b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>where our use of the data is
unlawful but you do not want us to erase it;</span></p>

<p class="MsoNormal section-list-letter" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:56.7pt;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB>c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>where you need us to hold the data
even if we no longer require it as you need it to establish, exercise or defend
legal claims; or</span></p>

<p class="MsoNormal section-list-letter" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:56.7pt;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB>d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>you have objected to our use of
your data but we need to verify whether we have overriding legitimate grounds
to use it.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:39.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Request the transfer of your
personal data to you or to a third party. We will provide to you, or a third
party you have chosen, your personal data in a structured, commonly used,
machine-readable format. Note that this right only applies to automated
information which you initially provided consent for us to use or where we used
the information to perform a contract with you.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:39.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Withdraw consent at any time where
we are relying on consent to process your personal data. However, this will not
affect the lawfulness of any processing carried out before you withdraw your
consent. If you withdraw your consent, we may not be able to provide certain
products or services to you. We will advise you if this is the case at the time
you withdraw your consent.</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:39.3pt;text-align:justify;text-indent:-.25in;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>You also have the right to ask us
not to continue to process your personal data for marketing purposes. </span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>For further information on each of
those rights, including the circumstances in which they do and do not apply,
please contact us (see ‘<b>How to contact us</b>’ below). You may also find it
helpful to refer to the </span><u><span lang=EN-GB style='font-size:10.0pt;
line-height:115%;font-family:"Arial",sans-serif;color:blue'>guidance from the
UK’s Information Commissioner</span></u><span lang=EN-GB style='font-size:10.0pt;
line-height:115%;font-family:"Arial",sans-serif;color:black'> on your rights
under the UK GDPR. </span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>If you would like to exercise any
of those rights, please email or write to us—see below: ‘How to contact us’.
When contacting us please:</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:35.45pt;text-align:justify;text-indent:-14.75pt;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
color:black'>provide enough information to identify yourself (eg your full
name, date of birth and username) any additional identity information we may
reasonably request from you, and</span></p>

<p class="MsoNormal section-list" style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:35.45pt;text-align:justify;text-indent:-14.75pt;line-height:115%;
border:none'><span lang=EN-GB style='font-family:"Noto Sans Symbols"'>●<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
color:black'>let us know which right(s) you want to exercise and the
information to which your request relates</span></p>

<h1><a name="_heading=h.3rdcrjn"></a><u><span lang=EN-GB>Keeping your
information secure</span></u></h1>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>We have appropriate security
measures to prevent your information from being accidentally lost, or used or
accessed unlawfully. We limit access to your information to those who have a
genuine business need to access it. </span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>We also have procedures in place to
deal with any suspected data security breach. We will notify you and any
applicable regulator of a suspected data security breach where we are legally
required to do so.</span></p>

<h1><a name="_heading=h.26in1rg"></a><u><span lang=EN-GB>How to complain</span></u></h1>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Please contact us if you have any queries
or concerns about our use of your information (see below ‘<b>How to contact us</b>’).
We hope we will be able to resolve any issues you may have.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>You also have the right to lodge a
complaint with the Information Commissioner in the UK

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>The Information Commissioner can be
contacted using the details at </span><u><span lang=EN-GB style='font-size:
10.0pt;line-height:115%;font-family:"Arial",sans-serif;color:blue'>https://ico.org.uk/make-a-complaint</span></u><span
lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
color:black'> or telephone: 0303 123 1113.</span></p>



<h1><a name="_heading=h.lnxbz9"></a><u><span lang=EN-GB>Changes to this privacy
policy</span></u></h1>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>We may change this privacy policy
from time to time. When we make significant changes we will take steps to
inform you, for example via the app or by other means, such as email.</span></p>

<h1><a name="_heading=h.35nkun2"></a><u><span lang=EN-GB>How to contact us</span></u></h1>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>You can contact us and/or our Data
Protection Officer<b> </b>by post, email or telephone if you have any questions
about this privacy policy or the information we hold about you, to exercise a
right under data protection law or to make a complaint.</span></p>

<p class="MsoNormal section" style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>Our contact details are shown
below:</span></p>

<table class=table border=1 cellspacing=0 cellpadding=0 style='margin-left:
 5.9pt;border-collapse:collapse;border:none'>
 <thead>
  <tr>
   <td width=297 valign=top style='width:223.0pt;border:solid black 1.0pt;
   padding:0in 5.75pt 0in 5.75pt'>
   <p class=MsoNormal style='text-align:justify'><span lang=EN-GB
   style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>Our
   contact details</span></p>
   </td>
   <td width=296 valign=top style='width:221.9pt;border:solid black 1.0pt;
   border-left:none;padding:0in 5.75pt 0in 5.75pt'>
   <p class=MsoNormal style='text-align:justify'><span lang=EN-GB
   style='font-size:10.0pt;line-height:107%;font-family:"Arial",sans-serif'>Our
   Data Protection Officer’s contact details</span></p>
   </td>
  </tr>
 </thead>
 <tr>
  <td width=297 valign=top style='width:223.0pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Cherry Dating Ltd</span></p>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'>
  <span lang="EN-GB" style="font-size:10.0pt;line-height:115%; font-family:'Arial',sans-serif;color:black">71-75 Shelton Street<br>
  Covent Garden<br>London<br>WC2H 9JQ
  </span></p>
  <p class="MsoNormal email-table" style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB><a
  href="mailto:Customerservices@cherrydating.co.uk"><span style='font-size:
  10.0pt;line-height:115%;font-family:"Arial",sans-serif'>customerservices@cherrydating.co.uk</span></a></span><span
  lang=EN-GB style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif;
  color:black'> </span></p>
  </td>
  <td width=296 valign=top style='width:221.9pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>Johanna Mason</span></p>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>71-75 Shelton Street Covent Garden London WC2H 9JQ</span></p>
  <p class="MsoNormal email-table" style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB><a
  href="mailto:Customerservices@cherrydating.co.uk"><span style='font-size:
  10.0pt;line-height:115%;font-family:"Arial",sans-serif'>customerservices@cherrydating.co.uk</span></a></span></p>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>
  </td>
 </tr>
</table>
<p class="MsoNormal email" style='margin-bottom:12.0pt;line-height:
  115%;border:none'><span lang=EN-GB><a
  href="mailto:Customerservices@cherrydating.co.uk"><span style='font-size:
  10.0pt;line-height:115%;font-family:"Arial",sans-serif'>customerservices@cherrydating.co.uk</span></a></span></p>
  <p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
  115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
  font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>
<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;line-height:
115%;border:none'><span lang=EN-GB style='font-size:10.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black'>&nbsp;</span></p>

</div>

</body>

</html>

`

const PrivacyPolicy: React.FC = () => {

  return (
    <Page title='Privacy & Policy' center={true} headerProps={{ white: true }}>
      <View variants={['marginTop:12', 'fullWidth', 'column']}>
        <View variants={['row', 'wrap', 'gap:2', 'marginBottom:2']}>
          <Text variants={['black']}>This is the Privacy & Policy Page Access the Terms & Conditions in this link: </Text>
          <Link variants={['black', 'underlined']} text={'Terms & Conditions'} to={'/terms-and-conditions'} />
        </View>
        <View dangerouslySetInnerHTML={{ __html: privacyPolicyHtml }}>
        </View>
      </View>
    </Page>
  )
}

export default PrivacyPolicy
