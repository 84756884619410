/* eslint-disable max-len */
/* eslint-disable max-lines */
import { Theme, variantProvider, React, AppImages } from '@/app'
import { AppStatusOverlay, Page, View, Link, Text, Icon, ActivityIndicator, Touchable, Image } from '@/components'
import { getRandomHomeImage, BackgroundDetails } from '@/utils'
import { onMount } from '@codeleap/common'
import { useState } from 'react'

const featuredInContent = [
  {
    subTitle: 'GLAMOUR UK',
    title: 'Best new dating app: Cherry',
    image: AppImages.GlamourUk,
    url: 'https://www.glamourmagazine.co.uk/gallery/best-dating-sites#:~:text=Best%20new%20dating,pool%E2%80%A6%20for%20now',
    description: "Who better to update you on a new route to love than the app's founder? Yep, Cherry is the latest in a long (long) line of dating apps, working to help millennials and Gen Z's-alike to find love — except it's doing it differently."
  },
  {
    subTitle: 'SKY NEWS',
    title: 'Phone calls, anti-catfish measures and vegan apps: The frustrated entrepreneurs taking on dating',
    image: AppImages.SkyNews,
    url: 'https://news.sky.com/story/phone-calls-anti-catfish-measures-and-vegan-apps-the-frustrated-entrepreneurs-taking-on-dating-13276624',
    description: "People have been coming up with ways to find true love for centuries; from Victorian promenading to tea dancing to lonely hearts columns, we're a species that likes to matchmake. Now, frustrated by traditional dating apps, these entrepreneurs are solving their own problems."
  },
]

const FeaturedInCard = (props) => {
  return (
    <View css={styles.featuredInCardWrapper}>
      <Touchable css={styles.mediaWrapper} onPress={() => window.open(props.url, '_blank')}>
        <Image
          source={props.image}
          css={styles.contentImage}
          objectFit={'cover'}
        />
      </Touchable>
      <View css={styles.textCardWrapper}>
        <Text text={props.subTitle} css={{color: '#808080'}} variants={['bold', 'paddingTop:2']} />
        <Text text={props.title} css={[styles.ellipsisText, styles.ellipsisTextOneLine]} variants={['h3', 'black700', 'bold', 'paddingTop:1']} />
        <Text text={props.description} css={[styles.ellipsisText, styles.ellipsisTextTwoLines]} variants={['black700', 'paddingTop:1']} />
      </View>
    </View>
  )
}

export const Overlays = () => {
  return (
    <>
      <AppStatusOverlay />
      {/* <DebugModal/> */}
    </>
  )
}

const StoreButtons = (props) => (
  <View
    variants={['gap:2', 'marginVertical:2']}
    {...props}
  >
    <Link to={'https://apps.apple.com/us/app/cherry-personalised-dating/id1673810759'}>
      <Icon name={'appStore'} style={styles.linkButton} />
    </Link>
    <Link to={'https://play.google.com/store/apps/details?id=uk.co.codeleap.cherry&hl=en_US'}>
      <Icon name={'googlePlay'} />
    </Link>
  </View>
)

const HomeText = ({ messages }) => (

  <View
    css={styles.descriptionWrapper}
    variants={['column']}
    responsiveVariants={{
      mid: ['alignCenter', 'justifyCenter'],
    }}
  >
    {messages?.map((el, index) => <Text key={index} text={el?.text} css={{ lineHeight: 1.1, fontSize: 72, color: Theme.colors.light.white }} variants={['bold', 'white', 'uppercase']} />)}
    <StoreButtons down={'small'} />
    <Text
      variants={['h3', 'white', 'semiBold', 'marginVertical:2']}
      responsiveVariants={{
        mid: ['textCenter'],
      }}
    >
   Cherry is committed to creating a fun, safe, and honest environment to help as many people as possible find the love of their life or a great companion. Cherry prioritizes its members and values integrity above all.
    </Text>
    <StoreButtons up={'small'} />
  </View>
)

const IndexPage: React.FC = () => {
  const [homeImages, setHomeImages] = useState<BackgroundDetails | null>(null)

  onMount(() => {
    setHomeImages(getRandomHomeImage())
  })

  const messages = homeImages?.messages

  if (!homeImages) {
    return (
      <View css={styles.loadingWrapper} variants={['alignCenter', 'justifyCenter', 'fullWidth']}>
        <ActivityIndicator />
      </View>
    )
  }

  return (
    <Page
      title='Home'
      variants={['bare', 'column']}
      center={true}
    >
      <View css={[
        styles.wrapper,
        styles.innerSpacing,
        {
          backgroundImage: `url(${homeImages?.imageNameDesktop})`,
          [Theme.media.down('mid')]: {
            backgroundImage: `url(${homeImages?.imageNameMobile})`,
          },
        },
      ]}>
        <View css={{ maxWidth: 1280 }}>
          <HomeText messages={messages} />
        </View>
      </View>
      <View css={[styles.innerWrapper, styles.featuredInWrapper]}>
        <Text text={'Featured in'} variants={['h3', 'black800']} />
        <View css={styles.cardWrapper}>
          {featuredInContent.map((el) => {
            return (
              <FeaturedInCard {...el} />
            )
          })}
        </View>
      </View>
    </Page>
  )
}

export default IndexPage

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    innerSpacing: {
      [Theme.media.down('xxlarge')]: {
        paddingLeft: Theme.spacing.value(16),
        paddingRight: Theme.spacing.value(16),
      },
      [Theme.media.down('large')]: {
        paddingLeft: Theme.spacing.value(12),
        paddingRight: Theme.spacing.value(12),
      },
      [Theme.media.down('largeish')]: {
        paddingLeft: Theme.spacing.value(8),
        paddingRight: Theme.spacing.value(8),
      },
      [Theme.media.down('mid')]: {
        paddingLeft: Theme.spacing.value(4),
        paddingRight: Theme.spacing.value(4),
      },
      [Theme.media.down('small')]: {
        paddingLeft: Theme.spacing.value(2),
        paddingRight: Theme.spacing.value(2),
      },
    },
    wrapper: {
      minHeight: '100vh',
      width: '100vw',
      maxWidth: '100%',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingTop: theme.spacing.value(16),
    },
    innerWrapper: {
      maxWidth: 1280,
      width: '100%',
      height: '100%',
      ...theme.spacing.paddingTop(10),
      flexDirection: 'column',
    },
    featuredInWrapper: {
      maxWidth: 1280,

      [Theme.media.down('xxlarge')]: {
        paddingLeft: 0,
        paddingRight: 0
      },

      [Theme.media.down('xlarge')]: {
        paddingLeft: Theme.spacing.value(12),
        paddingRight: Theme.spacing.value(12),
      },
      [Theme.media.down('largeish')]: {
        paddingLeft: Theme.spacing.value(8),
        paddingRight: Theme.spacing.value(8),
      },
      [Theme.media.down('mid')]: {
        paddingLeft: Theme.spacing.value(4),
        paddingRight: Theme.spacing.value(4),
      },
      [Theme.media.down('small')]: {
        paddingLeft: Theme.spacing.value(2),
        paddingRight: Theme.spacing.value(2),
      },
    },
    cardWrapper: {
      flexDirextion: 'row',
      ...theme.spacing.gap(5),
      ...theme.spacing.marginTop(3),

      [Theme.media.down('mid')]: {
        flexDirection: 'column',
      },
    },
    featuredInCardWrapper: {
      maxWidth: 400,
      flexDirection: 'column',

      [Theme.media.down('mid')]: {
        flexDirection: 'row',
        maxWidth: '100%',
        width: '100%'
      },

      [Theme.media.down('small')]: {
        flexDirection: 'column',
      },
    },
    textCardWrapper: {
      flexDirection: 'column',
      width: '100%',

      [Theme.media.down('mid')]: {
        ...theme.spacing.paddingLeft(3),
        ...theme.spacing.paddingTop(3)
      },

      [Theme.media.down('small')]: {
        ...theme.spacing.paddingLeft(0),
        ...theme.spacing.paddingTop(0)
      },
    },
    linkButton: {
      width: 150,
      height: 50,
    },
    imagePhone: {
      width: '60%',
      maxHeight: '80vh',
      objectFit: 'contain',
      opacity: 1,
      visibility: 'visible',
      [Theme.media.down('mid')]: {
        width: '100%',
      },
      [Theme.media.down('small')]: {
        opacity: 0,
        visibility: 'hidden',
      },
    },
    descriptionWrapper: {
      zIndex: 9,
      width: '40%',
      [Theme.media.down('mid')]: {
        width: '100%',
      },
    },
    loadingWrapper: {
      height: '100vh',
    },
    contentImage: {
			 width: '100%',
			 height: '100%',
			 transition: 'transform 0.3s ease',
			 ':hover': {
			 	transform: 'scale(1.05)',
			 },
		 },
    mediaWrapper: {
			 position: 'relative',
			 width: '100%',
			 minHeight: 225,
			 aspectRatio: '16/9',
			 cursor: 'pointer',
			 borderRadius: theme.borderRadius.xSmall,
			 overflow: 'hidden',
			 [theme.media.down('mid')]: {
			 	maxWidth: 280,
			 	minWidth: 280,
			 },
			 [theme.media.down('small')]: {
			 	maxWidth: '100%',
			 	minHeight: 200,
			 },
		 },
    ellipsisText: {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    ellipsisTextOneLine: {
      WebkitLineClamp: 1,
    },
    ellipsisTextTwoLines: {
      WebkitLineClamp: 2,
    },
  }),
  true,
)
